<template>
  <section class="new_css">
    <el-row class="new_header">
      <el-col
        v-if="buttonList.length > 0" :span="24" class="organizetoolbar" style="padding-bottom: 0px">
        <el-form :inline="true" ref="selectForm" :model="selectForm" class="select_form">
          <el-form-item label="岗位名称:">
            <el-input v-model="selectForm.Positions" placeholder="岗位名称"></el-input>
          </el-form-item>
          <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
        </el-form>
      </el-col>
    </el-row>
    <div class="calc-height">
      <el-table :data="users" highlight-current-row height="100%" @current-change="selectCurrentRow" @row-dblclick="handleShow" class="new_table">
        <!-- <el-table-column type="index" width="50"> </el-table-column> -->
        <el-table-column prop="Factory" label="公司"> </el-table-column>
        <el-table-column prop="DptName" label="部门"> </el-table-column>
        <el-table-column prop="PostName" show-overflow-tooltip label="岗位名称"> </el-table-column>
        <el-table-column prop="ExpectationNum" show-overflow-tooltip label="编制计划数"> </el-table-column>
        <el-table-column prop="ExistingNum" label="在编人数"></el-table-column>
        <el-table-column prop="DifferenceNum" label="缺编人数"></el-table-column>
        <!-- <el-table-column prop="AstrictSpecialty" show-overflow-tooltip label="岗位要求"> </el-table-column> -->
      </el-table>
    </div>
    <!-- 分页器 -->
    <el-pagination align="center" @current-change="handleCurrentChange" :current-page="currentPage" :page-size="pageSize" layout="prev, pager, next, jumper,total" :page-count="total" :total="totaldata">
    </el-pagination>


    <!-- 弹窗 -->
    <el-dialog :title="operation ? '新增岗位及编制' : '变更岗位及编制'" :visible.sync="SakaryVisible" v-model="SakaryVisible" :close-on-click-modal="false">
        <el-form ref="SakaryForm" :model="SakaryForm" label-width="100px">
            <el-row>
                <el-col :span="12">
                  <el-form-item label="岗位名称:" prop="PostName" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
                    <el-input type="text" :disabled="true" v-model="SakaryForm.PostName">
                      <el-button slot="append" icon="el-icon-search" @click="chosePost"></el-button>
                    </el-input>
                  </el-form-item>

                </el-col>
                <el-col :span="12" style="display:none;">
                    <el-form-item label="岗位ID:" prop="PostId">
                        <el-input type="text" :disabled="true" v-model="SakaryForm.PostId"> </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="公司:" prop="GS">
                        <el-input type="text" v-model="SakaryForm.Factory" :disabled="true"></el-input>
                    </el-form-item>
                </el-col>  
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="部门:" prop="GS">
                        <el-input type="text" v-model="SakaryForm.DptName" :disabled="true"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="新增/变更:" prop="type" :rules="rules">
                      <el-input type="text" :disabled="true" v-model="SakaryForm.type"> </el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                
                <el-col :span="12">
                    <el-form-item label="编制计划数:" prop="ExpectationNum" :rules="rules1">
                        <el-input type="text"  :disabled="text_ExpectationNum" v-model="SakaryForm.ExpectationNum"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="在编人数:" prop="ExistingNum">
                        <el-input type="text"  :disabled="text_ExistingNum" v-model="SakaryForm.ExistingNum"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="缺编人数:" prop="DifferenceNum">
                        <el-input type="text" :disabled="text_DifferenceNum" v-model="SakaryForm.DifferenceNum"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="变更人数:" prop="enlargeNum" :rules="rules2">
                        <el-input type="text" :disabled="text_enlargeNum" v-model="SakaryForm.enlargeNum"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-form-item label="变更描述:" prop="enlargeDescribe" :rules="rules3">
                        <el-input type="text" :disabled="text_enlargeDescribe" v-model="SakaryForm.enlargeDescribe"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            
            <el-row style="margin-bottom:14px;">
                <el-col :span="24">
                    <el-button type="primary" v-on:click="onPostExplain()">岗位说明书</el-button>
                </el-col>
            </el-row>
            <el-row v-if="operation1">
                <el-col :span="24" style="text-align: center">
                    <el-button type="primary" v-on:click="onSubmit('SakaryForm')">提交</el-button>
                </el-col>
            </el-row>
        </el-form>
    </el-dialog>

    <!-- 查看开始 -->
    <el-dialog title="岗位详情" :visible.sync="SakaryVisible1" v-model="SakaryVisible1" :close-on-click-modal="false">
        <el-form ref="SakaryForm1" :model="SakaryForm1" label-width="100px">
            <el-row>
                <el-col :span="12">
                  <el-form-item label="岗位名称:" prop="PostName">
                    <el-input type="text" v-model="SakaryForm1.PostName" :disabled="true"></el-input>
                  </el-form-item>

                </el-col>
                <el-col :span="12" style="display:none;">
                    <el-form-item label="岗位ID:" prop="PostId">
                        <el-input type="text" :disabled="true" v-model="SakaryForm1.PostId"> </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="公司:" prop="Factory">
                        <el-input type="text" v-model="SakaryForm1.Factory" :disabled="true"></el-input>
                    </el-form-item>
                </el-col>  
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="部门:" prop="DptName">
                        <el-input type="text" v-model="SakaryForm1.DptName" :disabled="true"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="编制数:" prop="ExpectationNum">
                        <el-input type="text" v-model="SakaryForm1.ExpectationNum" :disabled="true"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row style="display:none;">
                <el-col :span="12">
                    <el-form-item label="在编人数:" prop="ExistingNum">
                        <el-input type="text" v-model="SakaryForm1.ExistingNum"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="缺编人数:" prop="DifferenceNum">
                        <el-input type="text" v-model="SakaryForm1.DifferenceNum"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row style="margin-bottom:14px;">
                <el-col :span="24">
                    <el-button type="primary" v-on:click="onPostExplain1()">岗位说明书</el-button>
                </el-col>
            </el-row>
        </el-form>
    </el-dialog>
    <!-- 查看结束 -->
    <!-- <el-dialog :visible.sync="addUserVisible" v-model="addUserVisible" width="75%" center append-to-body>
      <UserChoosejob :data="choosedusers" :all="true" :single="false" @callback="usercallFunction"></UserChoosejob>
    </el-dialog> -->
    <el-dialog :visible.sync="addPostVisible" v-model="addPostVisible" width="75%" center append-to-body>
      <UserChoosejob :data="choosedPosts" :all="true" :single="false" @callback="chosePostcallFunction"></UserChoosejob>
    </el-dialog>

    <postExplainModal ref="postExplainModal" @callback="getData"></postExplainModal>
  </section>
</template>
<script>
import util from "../../../util/date";
import * as validate from "../../../util/validate";
import UploadFiles from "../../components/UploadFiles";
import {
  AddSalaRecruiting,
  GetPostEstablishment,
  GetPostExplainInfoList,
  GetDepartmentOrg,
  GetExpectationInfo,
  AddPostEstablishment,
  GetInfoByPostId,
} from "../../api/hr";
import { getButtonList } from "../../promissionRouter";
import UserChoose from "../../components/UserChoose";
import Toolbar from "../../components/newToolbar";
import UserChoosejob from "../../components/UserChoosejob";
import DptTool from "../../components/Org/DptTool"; // 选取部门控件
import postExplainModal from "../componentModals/PostExplainModalNew"    // 岗位职责
export default {
  components: { Toolbar, UserChoose, UploadFiles,UserChoosejob,DptTool,postExplainModal },
  data() {
    return {
      loading: true,
      currentPage: 1, // 当前页码
      total: null, // 总条数
      totaldata: null,
      operation: false, // true:新增, false:编辑
      operation1: true,
      pageSize: 20, // 每页的数据条数
      page: 1,
      searchVal: "",
      CertCategoryLists: [],
      CertProfessionLists: [],
      SavePlaceLists: [],
      currentRow: {}, // 选中行
      SakaryVisible: false,
      SakaryVisible1: false,
      choosedusers: [],
      selectForm: {
        Positions: null
      },
      addUserVisible: false,
      addPostVisible: false,
      text_ExistingNum:false,
      text_DifferenceNum:false,
      text_ExpectationNum:false,
      text_enlargeNum:false,
      text_enlargeDescribe:false,
      rules:[{ required: true, message: '不能为空', trigger: 'blur' },],
      rules1:[{ required: false, message: '不能为空', trigger: 'blur' },],
      rules2:[{ required: false, message: '不能为空', trigger: 'blur' },],
      rules3:[{ required: false, message: '不能为空', trigger: 'blur' },],
      choosedPosts: [],
      SakaryForm: {
        ExpectationNum:"",//编制数
        DifferenceNum:"",//缺编人数
        ExistingNum:"",
        PostName:"",//岗位名称
        PostId:"",//岗位ID
        type:"",//新增或变更
        Factory:"",//公司
        DptName:"",//部门
        PostExp:{},
      },
      SakaryForm1: {
        ExpectationNum:"",//编制数
        DifferenceNum:"",//缺编人数
        ExistingNum:"",
        PostName:"",//岗位名称
        PostId:"",//岗位ID
        type:"",//新增或变更
        Factory:"",//公司
        DptName:"",//部门
        PostExp:{},
      },
      users: [],
      buttonList: [],
    };
  },
  methods: {
    selectCurrentRow(val) {
      this.currentRow = val;
    },
    callFunction(item) {
      this[item.Func].apply(this, item);
    },
    usercallFunction(newdata) {
      this.SakaryForm.PostName= newdata.Name;
      this.SakaryForm.PostId = newdata.Id;
      console.log(newdata.DepartmentId);
        //获取公司
        // GetDepartmentOrg({ deptId: newdata.DepartmentId }).then((res) => {
        //   if (res.data.code == 200) {
        //     console.log(res.data.data);
        //     this.SakaryForm.GS = res.data.data.deptName;
        //     this.SakaryForm.DptName=res.data.data.queryDeptName;
        //   } else {
        //     this.$message({
        //       message: "获取部门信息出错",
        //       type: "error",
        //       duration: 5000,
        //       });
        //   }
        // });
        //岗位说明书
        GetPostExplainInfoList({ postId: newdata.Id }).then((res) => {
          console.log(res.data)
          if (res.data.status == 200) {
            
            
          } else {
            this.$message({
              message: "获取岗位职责信息出错",
              type: "error",
              duration: 5000,
            });
          }
        });
      this.addUserVisible = false;
    },
    onPostExplain(){//岗位说明书
      if (this.SakaryForm.PostId != null) {
        this.$refs.postExplainModal.operation = true; // 添加
        this.$refs.postExplainModal.optType = true;   // 编辑
        this.$refs.postExplainModal.onShowDlg(this.SakaryForm.PostId,this.SakaryForm.Factory,this.SakaryForm.PostName,this.SakaryForm.DptName);
      }else{
        this.$refs.postExplainModal.operation = true; // 添加
        this.$refs.postExplainModal.optType = true;   // 编辑
        this.$refs.postExplainModal.onShowDlg();
      }
    },
    onPostExplain1(){//岗位说明书
      if (this.SakaryForm.PostId != null) {
        this.$refs.postExplainModal.operation = true; // 添加
        this.$refs.postExplainModal.optType = true;   // 编辑
        this.$refs.postExplainModal.onShowDlg(this.SakaryForm1.PostId,this.SakaryForm1.Factory,this.SakaryForm1.PostName,this.SakaryForm1.DptName);
      }else{
        this.$refs.postExplainModal.operation = true; // 添加
        this.$refs.postExplainModal.optType = true;   // 编辑
        this.$refs.postExplainModal.onShowDlg();
      }
    },
    chosePostcallFunction(newdata) {
      // this.SakaryForm.PostName = newdata.Name;
      // this.SakaryForm.PostId = newdata.Id;
      console.log(newdata);
      GetDepartmentOrg({ deptId: newdata.DepartmentId }).then((res) => {
        if (res.data.code == 200) {
          console.log(res.data.data);
          if (res.data.data.deptName != null) {
            console.log(123)
            this.SakaryForm.PostName = newdata.Name;
            this.SakaryForm.PostId = newdata.Id;
            this.SakaryForm.Factory = res.data.data.deptName;
          } else {
          this.SakaryForm.Factory = "";
        }

        this.SakaryForm.DptName = res.data.data.queryDeptName;
      } else {
          this.$message({
            message: "获取部门信息出错",
            type: "error",
            duration: 5000,
          });
        }
      });
      this.addPostVisible = false;
    },
    chosePost() {
      this.addPostVisible = true;
    },
    handleEdit() {//变更
      let row = this.currentRow;
      console.log(row);
      if (!row.PostName) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });
        return;
      }
      this.SakaryForm.ExpectationNum=row.ExpectationNum,
      this.SakaryForm.PostName=row.PostName,
      this.SakaryForm.PostId=row.PostId,
      this.SakaryForm.DifferenceNum=row.DifferenceNum,
      this.SakaryForm.DptName=row.DptName,
      this.SakaryForm.ExistingNum=row.ExistingNum,
      this.SakaryForm.Factory=row.Factory,
      this.SakaryForm.type="变更";
      this.text_ExistingNum=true;
      this.text_DifferenceNum=true;
      this.text_ExpectationNum=true;
      this.text_enlargeNum=false;
      this.text_enlargeDescribe=false;
      this.rules1=[{ required: false, message: '不能为空', trigger: 'blur' },];
      this.rules2=[{ required: true, message: '不能为空', trigger: 'blur' },];
      this.rules3=[{ required: true, message: '不能为空', trigger: 'blur' },];

      this.SakaryVisible = true;
      this.operation = false;
      this.operation1 = true;
    },
    handleShow() {//查看
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要查看的一行数据！",
          type: "error",
        });
        return;
      }
      console.log(row);
      this.SakaryForm1.ExpectationNum=row.ExpectationNum,
      this.SakaryForm1.PostName=row.PostName,
      this.SakaryForm1.PostId=row.PostId,
      this.SakaryForm1.DifferenceNum=row.DifferenceNum,
      this.SakaryForm1.DptName=row.DptName,
      this.SakaryForm1.ExistingNum=row.ExistingNum,
      this.SakaryForm1.Factory=row.Factory,

      this.SakaryVisible1 = true;
      // this.operation = false;
      // this.operation1 = false;
    },
    handleAdd() {//新增
      (this.SakaryForm = {
        ExpectationNum:"",//编制数
        PostName:"",//岗位名称
        PostId:"",//岗位ID
        type:"",//新增或变更
      }),
      this.SakaryForm.type="新增",
      this.text_ExistingNum=true;
      this.text_DifferenceNum=true;
      this.text_ExpectationNum=false;
      this.text_enlargeNum=true;
      this.text_enlargeDescribe=true;
      this.rules=[{ required: true, message: '不能为空', trigger: 'blur' },];
      this.rules1=[{ required: true, message: '不能为空', trigger: 'blur' },];
      this.rules2=[{ required: false, message: '不能为空', trigger: 'blur' },];
      this.rules3=[{ required: false, message: '不能为空', trigger: 'blur' },];
      (this.operation = true), (this.SakaryVisible = true);
      this.operation1 = true;
    },
    //获取用户列表
    getData(isCurrent = true) {
      if (isCurrent) {
        this.page = 1;
      }

      let para = {
        page: this.page,
        size: this.pageSize,
        postname: this.selectForm.Positions,
      };
      GetPostEstablishment(para).then((res) => {
        console.log(res.data);
        this.total = res.data.response.pageCount;
        this.totaldata = res.data.response.dataCount;
        this.users = res.data.response.data;
        this.loading = false;
      });
    },
    getButtonList2(routers) {
      let _this = this;
      routers.forEach((element) => {
        let path = this.$route.path.toLowerCase();
        if (element.path && element.path.toLowerCase() == path) {
          _this.buttonList = element.children;
          return;
        } else if (element.children) {
          _this.getButtonList(element.children);
        }
      });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.page = val;
      this.getData(false);
    },
    onSubmit(formName) {//提交
      this.$confirm("确定要提交吗？", "提示", {}).then(() => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            // let para = Object.assign({}, this.SakaryForm);
            // console.log(para);

              GetInfoByPostId({ id: this.SakaryForm.PostId }).then((res) => {
                console.log(res.data)
                let para = {
                  Factory: this.SakaryForm.Factory,
                  DptName: this.SakaryForm.DptName,
                  PostName: this.SakaryForm.PostName,
                  PostId: this.SakaryForm.PostId,
                  type: this.SakaryForm.type,
                  ExpectationNum: this.SakaryForm.ExpectationNum,
                  ExistingNum: this.SakaryForm.ExistingNum,
                  DifferenceNum: this.SakaryForm.DifferenceNum,
                  PostExp:res.data.response,
                  enlargeNum: this.SakaryForm.enlargeNum,
                  enlargeDescribe: this.SakaryForm.enlargeDescribe,
                };
                console.log(res.data.response);
                if(res.data.response==undefined){
                  this.$message({
                    message: "未填写岗位说明书，请填写后提交！",
                    type: "error",
                  });
                }else{
                //发起流程
                AddPostEstablishment(para).then((res) => {
                  console.log(res.data);
                  if (res.data.success) {
                    this.$message({
                      message: "成功",
                      type: "success",
                      duration: 5000,
                    });
                    this.SakaryVisible = false;
                    this.getData();
                  } else {
                    this.$message({
                      message: "失败",
                      type: "error",
                      duration: 5000,
                    });
                  }
                });      
                }
              });
          } else {
            this.$message({
              message: "请检查必填项",
              type: "error",
              duration: 5000,
            });
            return false;
          }
        });
      });
    },
    
  },

  mounted() {
    this.getData();
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    let buttons = window.localStorage.buttList
      ? JSON.parse(window.localStorage.buttList)
      : [];
    this.buttonList = getButtonList(this.$route.path, routers, buttons);
  },
};
</script>
<style scoped>
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
.calc-height {
  height: calc(100vh - 300px) !important;
}
</style>

